import createApiClient from "./api";
// Create an Axios instance
const client = createApiClient();

export const api = {
  verifyEmail: (payload) =>
    client.post("/auth/email-verification", payload).then(({ data }) => data),

  resendOtp: (payload) =>
    client
      .post("/auth/resend-verification-otp", payload)
      .then(({ data }) => data),

  getCountries: () => {
    //external API to get countries and with their states
    const fetchCountries = async () => {
      const response = await fetch('https://countriesnow.space/api/v0.1/countries/states');
      if (!response.ok) {
        throw new Error('Failed to fetch states');
      }
      return response.json();
    };
    return fetchCountries()
  }
};

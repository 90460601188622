import createApiClient from "./api";

// Create an Axios instance
const client = createApiClient();

export const api = {
    /******************************************************************************************** */
      // affiliate dashboard related endpoints
      getAffiliateCampaigns: ({ perPage, currentPage, search, affiliateId }) =>
       client
      .get(`/admin/affiliates/${affiliateId}/campaigns?dropdown=0&per_page=${perPage}&page=${
         currentPage }${
            search ? `&search=${search}` : ''
         }`)
      .then(({ data }) => data),

      getDesignations: () => client .get(`/admin/designations`).then(({ data }) => data),

      getStates: () => client .get(`/states`).then(({ data }) => data ),

      getAffiliates: ({ perPage, currentPage, search }) =>
       client
      .get(`/admin/affiliates?dropdown=0&per_page=${perPage}&page=${currentPage}${
         search ? `&search=${search}` : ''
      }`)
      .then(({ data }) => data),

      getAffiliateLinks: (payload) =>
       client
      .get(`/admin/affiliates/${payload.affiliateId}/campaigns/${payload.campaignId}/links?per_page=1000`)
      .then(({ data }) => data), 
      
      createAffiliateLink: (payload) =>
       client
      .post(`/admin/affiliates/${payload.affiliateId}/campaigns/${payload.campaignId}/links`)
      .then(({ data }) => data), 

      createAffiliate: (payload) => client.post(`/admin/affiliates`, payload ).then(({ data }) => data), 
     
      toggleAffiliate: (payload) => client.put(`/admin/affiliates/${payload}/toggle-status`)
      .then(({ data }) => data),
}  
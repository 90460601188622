import axios from "axios";
import baseUrl from "../url";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";

const client = axios.create({
  baseURL: baseUrl,
  headers: {
    "Cache-Control": "no-cache",
  },
});

const key = process.env.REACT_APP_SECRET_KEY;

client.interceptors.request.use(
  async (config) => {
    const encryptedToken = sessionStorage.getItem("userToken");

    if (encryptedToken) {
      const decryptedToken = CryptoJS.AES.decrypt(encryptedToken, key);
      const token = decryptedToken.toString(CryptoJS.enc.Utf8);
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && error.config.url !== "/login") {
      sessionStorage.setItem("isLoggedIn", false);
      toast.remove();
      toast.error("Unauthenticated, kindly login again...");
    }
    return Promise.reject(error);
  }
);

export const api = {
  //LOGIN
  login: (payload) =>
    client.post("/auth/login", payload).then(({ data }) => data),

  logout: () => client.post("/auth/logout").then(({ data }) => data),

  getTypes: () => client.get(`/admin/types`).then(({ data }) => data),

  resetPassword: (payload) =>
    client
      .post(`/auth/reset-password`, { ...payload })
      .then(({ data }) => data),

  forgotPassword: (payload) =>
    client
      .post(`/auth/forgot-password`, {
        ...payload,
      })
      .then(({ data }) => data),

  //USERS
  getUsers: ({ pageNo, search, perPage }) =>
    client
      .get(`/admin/users?page=${pageNo}&search=${search}&per_page=${perPage}`)
      .then(({ data }) => data),

  createUser: (payload) =>
    client.post(`/admin/users`, { ...payload }).then(({ data }) => data),

  toggleUserStatus: (user_id) =>
    client
      .put(`/admin/users/${user_id}/toggle-status`)
      .then(({ data }) => data),

  //CLIENT
  createClient: (payload) =>
    client
      .post("/auth/register?account_type=client", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => data),

  verifyClient: ({ id, hash }) =>
    client.get(`auth/email/verify/${id}/${hash}`).then(({ data }) => data),

  verifyEmail: (payload) =>
    client.post("auth/email-verification", payload).then(({ data }) => data),

  //QUESTION BANK
  fetchQuestions: (pageNo, category_id, section_id, type, search, perPage) =>
    client
      .get(
        `/admin/questions?page=${pageNo}&category_id=${category_id}&section_id=${section_id}&type=${type}&search=${search}&per_page=${perPage}`
      )
      .then(({ data }) => data),

  uploadQuestions: (questions) =>
    client.post("/admin/questions/bulk-upload", questions).then((data) => data),

  deleteQuestion: (questionID) =>
    client.delete(`/admin/questions/${questionID}`).then((data) => data),

  createQuestion: (payload) =>
    client.post(`/admin/questions`, payload).then(({ data }) => data), 

  updateQuestion: (payload) =>
    client
      .put(`/admin/questions/${payload.questionID}`, payload.data)
      .then(({ data }) => data),

  //CATEGORIES
  fetchCategories: () =>
    client.get("/admin/categories").then(({ data }) => data),

  //SECTIONS
  fetchSections: () => client.get("/admin/sections").then(({ data }) => data),

  addSection: (payload) =>
    client.post("admin/sections", payload).then(({ data }) => data),

  //DIRECTIONS
  fetchDirections: () =>
    client.get("/admin/directions").then(({ data }) => data),

  createDirection: (params) =>
    client.post("/admin/directions", { ...params }).then(({ data }) => data),

  editDirection: ({ directionID, payload }) =>
    client
      .put(`/admin/directions/${directionID}`, { ...payload })
      .then(({ data }) => data),

  deleteDirection: (directionID) =>
    client.delete(`/admin/directions/${directionID}`).then((data) => data),

  //GROUPS
  fetchGroups: () => client.get("/admin/groups").then(({ data }) => data),

  deleteGroup: (groupID) =>
    client.delete(`/admin/groups/${groupID}`).then((data) => data),

  createGroup: (params) =>
    client.post("/admin/groups", { ...params }).then((data) => data),

  updateGroup: (id, params) =>
    client.put(`/admin/groups/${id}`, { ...params }).then((data) => data),

  fetchGroupCandidates: ({ group_id, pageNo, search, perPage }) =>
    client
      .get(
        `/admin/groups/${group_id}/candidates?page=${pageNo}&search=${search}&per_page=${perPage}`
      )
      .then(({ data }) => data),

  getCandidateResult: (user_group_id) =>
    client
      .get(`/admin/groups/candidates/${user_group_id}`)
      .then(({ data }) => data),

  getCategoryResult: (result_id) =>
    client
      .get(`/admin/groups/candidate-result-per-assessment/${result_id}`)
      .then(({ data }) => data),

  // Roles And Permissions
  fetchRolesAndPermissions: (pageNo) =>
    client.get(`/admin/designations?page=${pageNo}`).then(({ data }) => data),

  exportApplications: (campaign_id) =>
    client
      .get(`/admin/exports?slug=application&campaign_id=${campaign_id}`)
      .then(({ data }) => data),

  createRolesAndPermissions: (params) =>
    client.post("/admin/designations", { ...params }).then((data) => data),

  updateRolesAndPermissions: (payload) =>
    client
      .put(`/admin/designations/${payload.id}`, payload.data)
      .then(({ data }) => data),

  fetchPermissions: () =>
    client.get(`/admin/permissions?per_page=1000`).then(({ data }) => data),

  addPermissionsToDesignation: (payload) =>
    client
      .post(`/admin/designations/${payload.id}/update-permissions`, {
        ...payload.data,
      })
      .then((data) => data),

  /********************************************************************************************** */
  //newsletter endpoints
  getNewsletters: (filter) =>
    client
      .get(
        `/admin/newsletters${filter ? `?per_page=10${filter}` : "?per_page=10"}`
      )
      .then(({ data }) => data),
  //create newsletter
  createNewsletter: (payload) =>
    client.post(`/admin/newsletters`, { ...payload }).then(({ data }) => data),
  // delete newsletter
  deleteNewsletters: (id) =>
    client.delete(`/admin/newsletters/${id}`).then(({ data }) => data),
  //update newsletter
  updateNewsletters: (payload) =>
    client
      .put(`/admin/newsletters/${payload.id}`, { ...payload.data })
      .then(({ data }) => data),
  //send newsletter to subcribers
  sendNewsletters: (id) =>
    client.post(`/admin/newsletters/${id}`).then(({ data }) => data),
  /******************************************************************************************** */
  //ASSESSMENTS
  fetchAssessments: (pageNo, category_id, type, search) =>
    client
      .get(
        `/admin/assessments?page=1&category_id=${category_id}&type=${type}&search=${search}`
      )
      .then(({ data }) => data),

  fetchAllAssessments: (client_id) =>
    client.get(`/admin/assessments`).then(({ data }) => data),

  createAssessment: ({ client_id, payload }) =>
    client.post(`/admin/assessments`, { ...payload }).then(({ data }) => data),

  deleteAssessment: (id) =>
    client.delete(`/admin/assessments/${id}`).then((data) => data),

  showAssessment: (id) =>
    client.get(`/admin/assessments/${id}/preview`).then(({ data }) => data),

  //CAMPAIGNS
  getCampaigns: (id) =>
    client.get(`/admin/campaigns`).then((data) => data.data),

  getCampaign: ({ client_id, campaign_id }) =>
    client
      .get(`/admin/campaigns/find-details/${campaign_id}`)
      .then(({ data }) => data),

  createCampaign: ({ payload, id }) =>
    client.post(`/admin/campaigns`, { ...payload }).then(({ data }) => data),

  viewAttendanceRegister: ({ campaignID, type, period, outletID }) =>
    client
      .get(
        `/admin/attendance-register/${campaignID}?type=${type}&period=${period}&outlet_id=${outletID}`
      )
      .then(({ data }) => data),

  getFeedback: ({currentPage, perPage}) =>
    client.get(`/admin/feedbacks?page=${currentPage}&per_page=${perPage}`).then(({ data }) => data),

  //GET APPLICATIONS
  getApplications: ({ id, pageNo, status, search }) =>
    client
      .get(
        `/admin/campaigns/applications/${id}?page=${pageNo}&status=${status}&search=${search}`
      )
      .then(({ data }) => data.data),

  terminateExam: (user_group_id) =>
    client
      .post(`/admin/terminate-exam`, { user_group_id: user_group_id })
      .then(({ data }) => data),

  resetExam: (payload) =>
    client.post(`/admin/reset-result`, { ...payload }).then(({ data }) => data),

  //STAGES
  getStages: () => client.get("/admin/stages").then(({ data }) => data),

  //CAMPAIGNS - ROLES
  getRoles: (id) => client.get(`/admin/roles`).then((data) => data.data),

  createRole: ({ payload, id }) =>
    client.post(`/admin/roles`, { ...payload }).then(({ data }) => data),

  //CAMPAIGN - PIPELINE
  createPipeline: ({ params, id }) =>
    client.post(`/admin/pipelines`, { ...params }).then(({ data }) => data),

  //CAMPAIGN - GROUPS
  createAssessmentGroup: (payload) =>
    client.post(`/admin/groups`, { ...payload }).then(({ data }) => data),

  updateAssessmentGroup: ({ groupID, payload }) =>
    client
      .put(`/admin/groups/${groupID}`, { ...payload })
      .then(({ data }) => data),

  getGroupSummary: ({ campaign_id, type, period }) =>
    client
      .get(`/admin/groups/summary/${campaign_id}?type=${type}&period=${period}`)
      .then(({ data }) => data),

  getAssessmentGroups: (campaign_id) =>
    client
      .get(`/admin/groups?campaign_id=${campaign_id}`)
      .then(({ data }) => data),

  getGroupResult: (group_id) =>
    client
      .get(`/admin/groups/group-summary/${group_id}`)
      .then(({ data }) => data),

  deleteAssessmentGroup: (group_id) =>
    client.delete(`/admin/groups/${group_id}`).then(({ data }) => data),

  //CAMPAIGN - FORM FIELDS
  getFormFields: () =>
    client.get(`/admin/form-fields`).then(({ data }) => data),

  createFormField: (payload) =>
    client.post(`/admin/form-fields`, { ...payload }).then(({ data }) => data),

  //CAMPAIGN - APPLICATION FORM
  createForm: ({ payload, id }) =>
    client
      .post(`/admin/application-forms`, { ...payload })
      .then(({ data }) => data),

  //CAMPAIGN - GET CRITERIA
  getCriterias: () => client.get(`admin/criterias?per_page=1000`)
  .then(({ data }) => data),

  //CAMPAIGN - SAVE CRITERIA
  saveCriteria: (payload) =>
    client.post(`/admin/clients/${payload?.client_id}/criterias`, { ...payload?.data })
  .then(({ data }) => data),

  //CAMPAIGN - ADD CRITERIA TO PIPELINE
  addCriteria: (payload) =>
    client
      .post("/admin/criterias/add-pipeline", { ...payload })
      .then(({ data }) => data),

  //CAMPAIGN - ADMIN CRITERIAS
  getAdminCriterias: ({client_id, pageNo, perPage, search}) =>
    client.get(`/admin/clients/${client_id}/criterias?page=${pageNo}&per_page=${perPage}&search=${search}`)
  .then(({ data }) => data),

  deleteAdminCriteria: ({criteria_id, client_id}) =>
    client.delete(`/admin/clients/$${client_id}/criterias/${criteria_id}`)
  .then(({ data }) => data),

  //CAMPAIGN - GET OPERATORS
  getOperators: () => client.get("/admin/operators").then(({ data }) => data),

  //CAMPAIGN - GET COURSES
  getCourses: () => client.get("/courses").then(({ data }) => data),

  //CAMPAIGN - GET DEGREES
  getDegrees: () => client.get("/degrees").then(({ data }) => data),

  //CAMPAIGN - GET INSTITUTIONS
  getInstitutions: () => client.get("/institutions").then(({ data }) => data),

  //CAMPAIGN - CONFIGURE GROUP
  configureGroup: (payload) =>
    client
      .post(`/admin/configure-group`, { ...payload })
      .then(({ data }) => data),

  //PIPELINE _ ADD TO GROUP
  addToGroup: (payload) =>
    client
      .post(`/admin/groups/add-candidates`, { ...payload })
      .then(({ data }) => data),

  moveToGroup: ({ fromGroupID, toGroupID, campaignID, status, source }) =>
    client
      .get(
        `/admin/candidates/move-to-group?source=${source}&campaign_id=${campaignID}&status=${status}&from_group_id=${fromGroupID}&to_group_id=${toGroupID}`
      )
      .then(({ data }) => data),

  sendNotification: ({ group_id, slug, action }) =>
    client
      .post(
        `/admin/groups/send-notification/${group_id}?slug=${slug}&action=${action}`
      )
      .then(({ data }) => data),

  //CAMPAIGN STATS
  getCampaignStats: () =>
    client.get("/admin/campaigns/statistics").then(({ data }) => data),

  //APPLICATION STATS
  getApplicationStats: (id) =>
    client
      .get(`/admin/campaigns/application-statistics/${id}`)
      .then(({ data }) => data),

  //AMBASSADORS
  getAmbassadors: (campaign_id) =>
    client
      .get(`/admin/ambassadors/all-links/${campaign_id}`)
      .then(({ data }) => data),

  createAmbassador: (payload) =>
    client.post(`/admin/ambassadors`, { ...payload }).then(({ data }) => data),

  //----------- BILLING ENPOINTS--------------------------------------------------------//
  getBillingPlans: () => client.get("/plans").then(({ data }) => data),

  getBillingTopClients: (period) =>
    client
      .get(`/admin/billings/top-clients?period=${period}`)
      .then(({ data }) => data),

  getBillingSummary: (period) =>
    client
      .get(`/admin/billings/top-line-summary?period=${period}`)
      .then(({ data }) => data),

  getBillingMonthly: (period) =>
    client
      .get(`/admin/billings/monthly-top-ups?period=${period}`)
      .then(({ data }) => data),

  getClientBillingTransactions: ({ clientID, year, page, perPage }) =>
    client
      .get(
        `/admin/billings/transactions/client/${clientID}?year=${year}&page=${page}`
      )
      .then(({ data }) => data),

  getClientBillingInvoices: ({ clientID, year, page }) =>
    client
      .get(
        `/admin/billings/invoices/client/${clientID}?year=${year}&page=${page}`
      )
      .then(({ data }) => data),

  getClientBillingCandidates: ({ clientID, year, month, page }) =>
    client
      .get(
        `/admin/billings/candidates/client/${clientID}?year=${year}&month=${month}`
      )
      .then(({ data }) => data),

  topUpAccount: (payload) =>
    client
      .post("/admin/billings/initiate-bundle-purchase", { ...payload })
      .then(({ data }) => data),

  getBillingTransactions: (payload) =>
    client
      .get(`/admin/billings/transactions${payload ? "?search=" + payload : ""}`)
      .then(({ data }) => data),

  getBillingInvoices: (payload) =>
    client
      .get(`/admin/billings/invoices?year=${payload}`)
      .then(({ data }) => data),

  getBillingTransactionChart: (payload) =>
    client.get(`/admin/billings/summary/${payload}`).then(({ data }) => data),

  getCandidates: (payload) =>
    client
      .get(
        `/admin/billings/candidates?month=${payload.month}&year=${
          payload.year
        }${payload?.search ? "&search=" + payload?.search : ""}`
      )
      .then(({ data }) => data),

  //-------------------------------------------------------------------------------------//

  getClients: () => client.get("/admin/clients").then(({ data }) => data),

  getStates: () => client.get("/states").then(({ data }) => data),

  getOutlets: (id) => client.get(`/admin/clients/${id}/outlets`).then(({ data }) => data),

  createOutlets: (payload) =>
    client.post("/admin/outlets", { ...payload }).then(({ data }) => data),

  createBooking: (payload) =>
    client.post("/admin/bookings", { ...payload }).then(({ data }) => data),

  generateVenueCode: (campaignID) =>
    client.post(`/admin/bookings/${campaignID}`).then(({ data }) => data),

  updateLocation: (payload) =>
    client.post(`/admin/update-booking`, payload).then(({ data }) => data),

  getDates: (campaignID) =>
    client.get(`/admin/bookings/dates/${campaignID}`).then(({ data }) => data),

  changeGroup: (payload) =>
    client
      .post(`/admin/change-candidate-group`, payload)
      .then(({ data }) => data),

  createBulkUpload: (payload) =>
    client
      .post("/admin//candidates/import", { ...payload })
      .then(({ data }) => data),

  getBookings: ({ campaign_id, pageNo, perPage, search }) =>
    client
      .get(
        `/admin/bookings/${campaign_id}?page=${pageNo}&per_page=${perPage}&search=${search}`
      )
      .then(({ data }) => data),

  updateBooking: ({ bookingID, payload }) =>
    client
      .put(`/admin/bookings/${bookingID}`, { ...payload })
      .then(({ data }) => data),

  deactivateAccount: (payload) =>
    client
      .post(`/admin/users/deactivate-account`, payload)
      .then(({ data }) => data),

  updateUserPassword: (payload) =>
    client
      .post(`/admin/users/change-password`, payload)
      .then(({ data }) => data),

  FlagQuestion: (questionId) =>
    client.post(`/flag-question/${questionId}`).then(({ data }) => data),

  sendFlagQuestions: (questionId, payload) =>
    client
      .post(`/admin/flagged-questions/${questionId}`, { ...payload })
      .then(({ data }) => data),

  viewFlaggedQuestions: ({ currentPage, perPage, search }) =>
    client
      .get(
        `/admin/questions/flagged-questions?search=${search}&page=${currentPage}&per_page=${perPage}`
      )
      .then(({ data }) => data),

  // EXAM MONITOR
  getExamMonitorSummary: (type, period) =>
    client
      .get(`/exam-monitor-summary?type=${type}&period=${period}`)
      .then(({ data }) => data),

  getExamMonitor: (type, period, search, filter) =>
    client
      .get(
        `/exam-monitor?type=${type}&period=${period}&search=${search}&filter=${filter}`
      )
      .then(({ data }) => data),

  getRecentActivity: (type, period) =>
    client
      .get(`auth/login-activity?type=${type}&period=${period}`)
      .then(({ data }) => data),

  getCandidates: (query, page) =>
    client
      .get(`/admin/candidates?search=${query}&page=${page}`)
      .then(({ data }) => data),
  getCandidateProfile: (user_id) =>
    client.get(`/admin/candidates/profile/${user_id}`).then(({ data }) => data),
  getCandidateBiodata: (user_id) =>
    client
      .get(`/admin/candidates/profile/${user_id}/?section=bio_data`)
      .then(({ data }) => data),
  getCandidateExperience: (user_id) =>
    client
      .get(`/admin/candidates/profile/${user_id}/?section=experience`)
      .then(({ data }) => data),
  getCandidateEducation: (user_id) =>
    client
      .get(`/admin/candidates/profile/${user_id}/?section=education`)
      .then(({ data }) => data),
  getCandidateNyscDetail: (user_id) =>
    client
      .get(`/admin/candidates/profile/${user_id}/?section=nysc`)
      .then(({ data }) => data),
  getCandidateExamHistory: (user_id) =>
    client.get(`/admin/candidates/history/${user_id}`).then(({ data }) => data),
  getCandidateApplicationDetail: (user_id) =>
    client
      .get(`/admin/candidates/application-history/${user_id}`)
      .then(({ data }) => data),
};
